import { observable, action, computed, makeObservable } from 'mobx'
import FactoryProvider from 'providers/factoryProvider'
import { profileMap } from 'providers/helpers/profile'
import { CyrillicNameWithSpace, notifyError, notifySuccess, validRequired, validEmail } from 'uikit'
import { prepareErrorMessage } from 'utils/error'
import BaseModel from '../../baseModel'

class ProfileModel extends BaseModel {
  @observable id = ''
  @observable role = ''
  @observable fullName = ''
  @observable lastName = ''
  @observable firstName = ''
  @observable patronymic = ''
  @observable mobilePhone = ''
  @observable email = ''
  @observable isConfirmEmail = false
  @observable isConfirmMobilePhone = false
  @observable isProcessChange = false

  @observable isChangeEmail = false
  @observable isProcessSendEmail = false
  @observable isSendEmail = false
  @observable isSendConfirmEmail = false

  @observable isForceValidate = false
  @observable isProcessSave = false

  @observable referUrl = ''
  @observable canGenerateReferCode = false
  @observable showManagerMotivation = false
  @observable iconColor = 'primary'

  @observable motivationUrl = ''

  userChannelSubId = null

  constructor() {
    super()
    makeObservable(this)
  }

  clear = action(() => {
    this.isChangeEmail = false
    this.isProcessSendEmail = false
    this.isSendEmail = false
  })

  @computed get validLastName() {
    if (validRequired(this.lastName)) validRequired(this.lastName)
    if (!CyrillicNameWithSpace.test(this.lastName)) return 'Только кириллица'
    return null
  }

  @computed get validFirstName() {
    if (validRequired(this.firstName)) validRequired(this.firstName)
    if (!CyrillicNameWithSpace.test(this.firstName)) return 'Только кириллица'
    return null
  }

  @computed get validPatronymic() {
    if (this.patronymic !== '' && !CyrillicNameWithSpace.test(this.patronymic)) return 'Только кириллица'
    return null
  }

  @computed get validEmail() {
    if (validRequired(this.email)) return validRequired(this.email)
    if (validEmail(this.email)) return validEmail(this.email)
    return null
  }

  @computed get isAccessSendEmailCode() {
    return !this.isSendEmail && !this.isChangeEmail && !this.isConfirmEmail
  }

  @computed get isValid() {
    return !this.validLastName && !this.validFirstName && !this.validPatronymic &&
      !this.validEmail
  }

  @computed get isAccessChangeEmail() {
    return this.isConfirmEmail && !this.isChangeEmail
  }

  @computed get isAccessEditEmail() {
    return !this.isConfirmEmail && !this.isSendConfirmEmail
  }

  setEmail = action(email => {
    this.email = email
    this.isChangeEmail = true
    this.isSendEmail = false
  })

  applyProfile = action(profile => {
    this.applyData({
      ...profileMap({ profile }),
      isForceValidate: false,
      isProcessSave: false,
      isChangeEmail: false,
      isSendEmail: false
    })

    this.userChannelSubscribe()
  })

  userChannelSubscribe = () => {
    if (!this.userChannelSubId && this.id) {
      this.userChannelSubId = FactoryProvider.ActionCableProvider.addSubscription({
        channel: 'UserChannel',
        options: {
          user_id: this.id
        }
      })
    }
  }

  userChannelRemove = () => {
    if (this.userChannelSubId) {
      FactoryProvider.ActionCableProvider.removeSubscription(this.userChannelSubId)
      this.userChannelSubId = null
    }
  }

  setConfirmEmail = action(isConfirm => {
    this.isConfirmEmail = isConfirm
    this.isChangeEmail = false
    this.isSendEmail = false
    this.isSendConfirmEmail = false
  })

  get = () => ({
    lastName: this.lastName,
    firstName: this.firstName,
    patronymic: this.patronymic,
    email: this.email,
    mobilePhone: this.mobilePhone
  })

  currentUser = () => ({
    id: this.id,
    fullName: this.fullName,
    lastName: this.lastName,
    firstName: this.firstName,
    patronymic: this.patronymic,
    mobilePhone: this.mobilePhone,
    email: this.email,
    isConfirmEmail: this.isConfirmEmail,
    isConfirmMobilePhone: this.isConfirmMobilePhone
  })

  sendEmailConfirmCode = async () => {
    try {
      this.applyData({ isProcessSendEmail: true })
      await FactoryProvider.UserProvider.sendConfirmEmailCode(this.id)
      this.applyData({ isSendEmail: true })
      notifySuccess('Код подтверждения отправлен на указанный адрес электронной почты.')
    } catch (e) {
      notifyError('Ошибка отправки кода подтверждения Email', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isProcessSendEmail: false })
    }
  }

  saveProfile = async () => {
    if (!this.isValid) {
      this.applyData({ isForceValidate: true })
      return
    }

    try {
      this.applyData({ isProcessSave: true })
      const data = await FactoryProvider.UserProvider.saveProfile(this.get())
      this.applyProfile(data?.me)
      if (data?.confirmation_sent) this.applyData({ isSendEmail: true })
      notifySuccess('Профиль успешно обновлён')
    } catch (e) {
      notifyError('Ошибка обновления профиля', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isProcessSave: false })
    }
  }

  getReferUrl = async () => {
    const { url } = await FactoryProvider.UserProvider.getReferUrl()
    this.applyData({ referUrl: url })
  }

  changeEmail = async email => {
    try {
      this.applyData({ isProcessChange: true })
      await FactoryProvider.UserProvider.changeEmail(this.id, email)
      this.applyData({
        isChangeEmail: true,
        isConfirmEmail: false,
        isSendConfirmEmail: true,
        email
      })
    } catch (e) {
      notifyError('Ошибка смены Email', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isProcessChange: false })
    }
  }
}

export { ProfileModel }
