import { observable, computed, action, makeObservable } from 'mobx'
import _ from 'lodash'
import FactoryProvider from 'providers/factoryProvider'
import FactoryModel from 'models/factoryModel'
import { push } from 'utils/history'
import deepMerge from 'deepmerge'
import {
  notifyError, notifySuccess, scrollToTop, moment, dateGreaterThan, validRequired, validEmail, safeObject, isSafe, DATE_FORMAT
} from 'uikit'
import { prepareErrorMessage } from 'utils/error'
import { validationAddress } from 'utils/validators/address'
import BaseModel from '../baseModel'
import { BankAccountModel } from './bankAccountModel'

class PointModel extends BaseModel {
  constructor() {
    super()

    const bankAccountModel = new BankAccountModel()
    bankAccountModel.ctx = {
      CommonModel: this
    }
    this.BankAccountModel = bankAccountModel
    makeObservable(this)
  }

  PARTNER_ORGANIZATION_BLOCK = 'partner:organization'
  BANK_ACCOUNTS_BLOCK = 'bank:accounts'

  @observable id = ''

  @observable isActive = true
  @observable isProcessLock = false
  @observable lockReason = ''

  @observable partnerOrganization = null
  @observable isChangePartner = false
  @observable isProcessChangePartner = false
  prevPartnerOrganization = null

  @observable name = ''
  @observable partnerCode = ''
  @observable address = null
  @observable isOnline = false
  @observable isInitialPaymentCorrection = false
  @observable isRoundCents = false
  @observable isFreezeInitialPayment = false
  @observable macroRegion = null
  @observable retail = null
  @observable segment = null
  @observable isRequiredSegment = true
  @observable partnerBank = null
  @observable managers = []
  @observable isSendSalesReport = true
  @observable salesReportRecipients = []
  @observable callCenter = null
  @observable callCenterAgent = null
  @observable isCallCenterScansRequired = true
  @observable isCallCenterWidgetSendToDefaultOwner = true
  @observable services = []

  @observable partners = []
  @observable isLoadingPartners = true
  @observable macroRegions = []
  @observable retails = []
  @observable isLoadingRetails = true
  @observable segments = []
  @observable isLoadingSegments = true
  @observable banks = []
  @observable isLoadingBanks = true
  @observable managersList = {}
  @observable isLoadingManagersList = {}
  @observable prepareManagersList = []
  @observable isLoadingPrepareManagersList = true
  @observable callCenterList = []
  @observable isLoadingCallCenterList = true
  @observable callCenterAgentList = []
  @observable isLoadingCallCenterAgentList = true
  @observable serviceList = []
  @observable isLoadingServiceList = true
  @observable sales = {}
  @observable isLoadingSales = true

  @observable isLoadingPoint = false
  @observable isProcessSave = false
  @observable isShowForceValidate = false

  @observable historyEdit = []
  @observable isLoadingHistoryEdit = false

  @observable codes = []

  @observable agentCommissionGroups = []
  @observable newACGroupID = null
  @observable isSavingACGroups = false
  @observable isShowForceValidateACGroups = false

  @observable isShowForceValidateCodes = false
  @observable isLoadingCodes = false
  @observable isSavingCodes = false
  @observable bankCode = null

  @observable goodsCategories = [] // categories dictionary
  @observable isLoadingCategories = false

  @observable bankMixID = ''
  @observable partnerBankMix = ''

  @observable settings = []
  @observable isLoadingSettings = true
  @observable isProcessSaveSettings = false

  @observable accreditation = []
  @observable isLoadingAccreditation = true
  @observable isProcessSaveAccreditation = false

  @observable comment = ''
  @observable isSavingCallCenterInfo = false
  @observable callCenterInfo = ''

  clear = action(() => {
    this.id = ''

    this.isActive = true
    this.isProcessLock = false
    this.lockReason = ''

    this.partnerOrganization = null
    this.isChangePartner = false
    this.isProcessChangePartner = false
    this.prevPartnerOrganization = null

    this.name = ''
    this.partnerCode = ''
    this.address = null
    this.isOnline = false
    this.isInitialPaymentCorrection = false
    this.isRoundCents = false
    this.isFreezeInitialPayment = false
    this.macroRegion = null
    this.retail = null
    this.segment = null
    this.isRequiredSegment = true
    this.partnerBank = null
    this.managers = []
    this.isSendSalesReport = true
    this.salesReportRecipients = []
    this.callCenter = null
    this.callCenterAgent = null
    this.isCallCenterScansRequired = true
    this.isCallCenterWidgetSendToDefaultOwner = true
    this.services = []

    this.partners = []
    this.isLoadingPartners = true
    this.macroRegions = []
    this.retails = []
    this.isLoadingRetails = true
    this.segments = []
    this.isLoadingSegments = true
    this.banks = []
    this.isLoadingBanks = true
    this.managersList = {}
    this.isLoadingManagersList = {}
    this.prepareManagersList = []
    this.isLoadingPrepareManagersList = true
    this.callCenterList = []
    this.isLoadingCallCenterList = true
    this.callCenterAgentList = []
    this.isLoadingCallCenterAgentList = true
    this.serviceList = []
    this.isLoadingServiceList = true
    this.sales = {}
    this.isLoadingSales = true

    this.isLoadingPoint = false
    this.isProcessSave = false
    this.isShowForceValidate = false

    this.agentCommissionGroups = []
    this.newACGroupID = null
    this.isSavingACGroups = false
    this.isShowForceValidateACGroups = false

    this.codes = []
    this.isShowForceValidateCodes = false
    this.isLoadingCodes = false
    this.isSavingCodes = false
    this.bankCode = null

    this.goodsCategories = []
    this.isLoadingCategories = false

    this.historyEdit = []
    this.isLoadingHistoryEdit = false

    this.bankMixID = ''
    this.partnerBankMix = ''

    this.settings = []
    this.isLoadingSettings = false
    this.isProcessSaveSettings = false

    this.accreditation = []
    this.isLoadingAccreditation = false
    this.isProcessSaveAccreditation = false

    this.comment = ''
    this.callCenterInfo = ''
    this.isSavingCallCenterInfo = false

    this.BankAccountModel.clear()
  })

  dataFromPartner = {}

  clearDataFromPartner = action(() => {
    this.dataFromPartner = {}
  })

  applyDataFromPartner = action(() => {
    if (!this.dataFromPartner?.partner) return

    if (this.partners.indexOf(this.dataFromPartner.partner) === -1) {
      const partners = this.partners.concat(this.dataFromPartner.partner)
      this.applyData({ partners })
    }

    this.applyData({ partnerOrganization: this.dataFromPartner.partner.id })
    this.BankAccountModel.applyParams({ partnerId: this.partnerOrganization })
  })

  @computed get validLockReason() {
    return validRequired(this.lockReason)
  }

  @computed get validName() {
    if (validRequired(this.name)) return validRequired(this.name)
    if (this.name.length < 5) return 'Длина меньше 5'
    return null
  }

  @computed get validPartnerOrganization() {
    if (validRequired(this.partnerOrganization)) return validRequired(this.partnerOrganization)
    return null
  }

  @computed get validAddress() {
    if (validRequired(this.address)) return validRequired(this.address)
    if (validationAddress(this.address).validation) return 'Некорректный адрес, воспользуйтесь ручным вводом'
    if (validRequired(this.macroRegion)) return 'Код региона не найден ни для одного Макрорегиона. Проверьте заполнение адреса'
    return null
  }

  @computed get validMacroRegion() {
    if (validRequired(this.macroRegion)) return validRequired(this.macroRegion)
    return null
  }

  @computed get validRetail() {
    if (validRequired(this.retail)) return validRequired(this.retail)
    return null
  }

  @computed get validSegment() {
    if (this.isRequiredSegment && validRequired(this.segment)) return validRequired(this.segment)
    return null
  }

  @computed get isValidManagers() {
    if (_.isEmpty(this.managers)) return false
    return !(_.reduce(this.managers, (s, r) => (this.validManagerId(r.id) || this.validManagerStartDate(r.startDate) ? s + 1 : s), 0) > 0)
  }

  @computed get isValidCodes() {
    if (_.isEmpty(this.codes)) return true
    const required = this.codes.map(code => this.isRequiredCodeValue(code))
    const flattened = _.reduce(required, (s, r) => [...s, ...r.values], [])
    return _.reduce(flattened, (s, r) => s + (!r.value ? 1 : 0), 0) === 0
  }

  isRequiredCodeValue = (code) => {
    const bankTemplates = _.find(FactoryModel.BankModel.banks, b => b.id === code.peerID)?.templates.filter(t => !!t.isRequired)
    const values = _.intersectionBy(code.values, bankTemplates, 'name')
    return { ...code, values }
  }

  @computed get isValidBankCode() {
    return isSafe(this.bankCode) &&
        _.findIndex(this.codes, code => code.peerID === this.bankCode) === -1
  }

  @computed get isProcessSettings() {
    return this.isLoadingSettings || this.isProcessSaveSettings
  }

  @computed get isProcessAccreditation() {
    return this.isLoadingAccreditation || this.isProcessSaveAccreditation
  }

  validManagerId(id) {
    if (validRequired(id)) return validRequired(id)
    return null
  }

  validManagerStartDate(startDate) {
    if (validRequired(startDate)) return validRequired(startDate)
    const old = dateGreaterThan(startDate, moment()
      .subtract(99, 'years'), 'Не может быть больше 99 лет')
    if (old) return old

    return null
  }

  @computed get isValidSalesReportRecipients() {
    if (!this.isSendSalesReport) return true
    if (_.isEmpty(this.salesReportRecipients)) return false
    return !(_.reduce(this.salesReportRecipients, (s, r) => (r === '' || validEmail(r) ? s + 1 : s), 0) > 0)
  }

  @computed get isNext() {
    return !this.validName && !this.validPartnerOrganization && !this.validAddress && !this.validMacroRegion &&
      !this.validRetail && !this.validSegment && this.isValidManagers &&
      this.isValidSalesReportRecipients &&
      this.BankAccountModel.isExistAccountsAssignedCurrentPoint &&
      !this.isChangePartner
  }

  validCodeValue(value, isRequired) {
    if (isRequired && validRequired(value)) return validRequired(value)
    return null
  }

  getPartners = async (params = {}) => {
    const { search = '' } = params
    try {
      this.applyData({ isLoadingPartners: true })
      const list = await FactoryProvider.PartnerProvider.searchPartners({ search })
      this.applyData({
        partners: _.map(list, m => ({ id: m.id, name: m.name }))
      })
    } finally {
      this.applyData({ isLoadingPartners: false })
    }
  }

  setPartner = async partnerOrganization => {
    if (partnerOrganization === this.partnerOrganization) return
    if (!this.isChangePartner && this.id) {
      this.applyData({ prevPartnerOrganization: this.partnerOrganization, isChangePartner: true })
    }
    this.applyData({ partnerOrganization })
    if (partnerOrganization) {
      this.BankAccountModel.applyParams({ partnerId: partnerOrganization, pointId: this.id })
    }
  }

  changePartner = async () => {
    try {
      this.applyData({ isProcessChangePartner: true })
      await FactoryProvider.PointProvider.changePartner(this.partnerOrganization, this.id, this.BankAccountModel.singleAccount)
      notifySuccess('Смена организации прошла успешно')
      this.applyData({ prevPartnerOrganization: null, isChangePartner: false })
      this.BankAccountModel.applyParams({ partnerId: this.partnerOrganization, pointId: this.id })
    } catch (e) {
      notifyError('Ошибка смены организации', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isProcessChangePartner: false })
    }
  }

  cancelChangePartner = action(() => {
    this.isChangePartner = false
    this.partnerOrganization = this.prevPartnerOrganization
    this.prevPartnerOrganization = null
    this.BankAccountModel.applyParams({
      partnerId: this.partnerOrganization,
      pointId: this.id
    })
  })

  getMacroRegions = async () => {
    const list = await FactoryProvider.RegionProvider.searchMacroRegions(false)
    this.applyData({
      macroRegions: _.uniqBy(deepMerge(this.macroRegionsList, list), 'id')
    })
  }

  setAddress = (v) => {
    const regionKladrCode = v.isWithoutKladr ? v?.regionCode : v?.kladrID?.slice(0, 2)
    const macroRegion = this.macroRegions.find(mr => mr.regionCodes.indexOf(regionKladrCode) !== -1)
    this.applyData({
      address: v,
      macroRegion: macroRegion?.id
    })
  }

  getRetails = async (params = {}) => {
    const { search = '' } = params
    try {
      this.applyData({ isLoadingRetails: true })
      const list = await FactoryProvider.RetailProvider.searchRetails({ search })
      this.applyData({
        retails: _.map(list, l => ({ id: l.id, name: l.name }))
      })
    } finally {
      this.applyData({ isLoadingRetails: false })
    }
  }

  getSegments = async (params = {}) => {
    const { search = '' } = params
    try {
      this.applyData({ isLoadingSegments: true })
      const list = await FactoryProvider.PointProvider.searchPointSegments({ search })
      this.applyData({
        segments: _.map(list, l => ({ id: l.id, name: l.name }))
      })
    } finally {
      this.applyData({ isLoadingSegments: false })
    }
  }

  getBanks = async () => {
    try {
      this.applyData({ isLoadingBanks: true })
      const list = await FactoryProvider.BankProvider.searchBanks()
      this.applyData({
        banks: _.map(list, l => ({ id: l.id, name: l.name }))
      })
    } finally {
      this.applyData({ isLoadingBanks: false })
    }
  }

  getSales = async () => {
    if (!this.id) return
    if (!_.isEmpty(this.sales)) return

    try {
      this.applyData({ isLoadingSales: true })
      const sales = await FactoryProvider.PointProvider.getSales(this.id)
      this.applyData({ sales })
    } finally {
      this.applyData({ isLoadingSales: false })
    }
  }

  getManagersList = async (params = {}) => {
    const { search = '', index, isPrepare = false } = params
    try {
      if (!isPrepare) {
        this.applyData({
          isLoadingManagersList: {
            ...this.isLoadingManagersList,
            [index]: true
          }
        })
      } else {
        this.applyData({ isLoadingPrepareManagersList: true })
      }
      const list = _.map(await FactoryProvider.UserProvider.searchUsers({ search, isActive: true, isPointManager: true }),
        l => ({ id: l.id, name: l.fullName || l.login }))
      if (!isPrepare) {
        const resolveList = deepMerge([], this.managersList)
        resolveList[index] = list
        this.applyData({ managersList: resolveList })
      } else {
        this.applyData({
          prepareManagersList: list
        })
      }
    } finally {
      if (!isPrepare) {
        this.applyData({
          isLoadingManagersList: {
            ...this.isLoadingManagersList,
            [index]: false
          }
        })
      } else {
        this.applyData({ isLoadingPrepareManagersList: false })
      }
    }
  }

  addManager = action(() => {
    this.managers.push({
      id: null,
      startDate: null
    })
    this.managersList[this.managers.length - 1] = deepMerge([], this.prepareManagersList)
  })

  setManager = action((index, value, key) => {
    this.managers[index][key] = value
  })

  removeManager = action(index => {
    this.managers.splice(index, 1)
  })

  addSalesReportRecipients = action(() => {
    this.salesReportRecipients.push('')
  })

  setSalesReportRecipients = action((index, value) => {
    this.salesReportRecipients[index] = value
  })

  removeSalesReportRecipients = action(index => {
    this.salesReportRecipients.splice(index, 1)
  })

  getCallCenterList = async () => {
    try {
      this.applyData({ isLoadingCallCenterList: true })
      const list = await FactoryProvider.CallCenterProvider.searchCallCenters()
      this.applyData({
        callCenterList: _.map(list, l => ({ id: l.id, name: l.name }))
      })
    } finally {
      this.applyData({ isLoadingCallCenterList: false })
    }
  }

  getCallCenterAgentList = async (params = {}) => {
    try {
      const { search = '' } = params
      this.applyData({ isLoadingCallCenterAgentList: true })
      const list = await FactoryProvider.UserProvider.searchUsers(safeObject({
        search,
        pointID: this.id || null
      }))
      this.applyData({
        callCenterAgentList: _.map(list, l => ({ id: l.id, name: l.fullName || l.login }))
      })
    } finally {
      this.applyData({ isLoadingCallCenterAgentList: false })
    }
  }

  getServiceList = async (params = {}) => {
    try {
      const { search = '' } = params
      this.applyData({ isLoadingServiceList: true })
      const list = await FactoryProvider.ServiceProvider.searchServices({ search })
      this.applyData({
        serviceList: _.map(list, l => ({
          id: l.id,
          name: l.name
        }))
      })
    } finally {
      this.applyData({ isLoadingServiceList: false })
    }
  }

  pointFields = () => [
    'id',
    'isActive',
    'lockReason',
    'name',
    'partnerCode',
    'partnerOrganization',
    'address',
    'isOnline',
    'isInitialPaymentCorrection',
    'isRoundCents',
    'isFreezeInitialPayment',
    'macroRegion',
    'retail',
    'segment',
    'partnerBank',
    'managers',
    'isSendSalesReport',
    'salesReportRecipients',
    'callCenter',
    'callCenterAgent',
    'isCallCenterScansRequired',
    'isCallCenterWidgetSendToDefaultOwner',
    'services',
    'bankMixID',
    'partnerBankMix',
    'agentCommissionGroups',
    'comment',
    'callCenterInfo'
  ]

  point = (point = null) => {
    const resolve = {}
    _.each(this.pointFields(), field => {
      resolve[field] = point ? point[field] : this[field]
    })

    resolve.singleAccount = this.BankAccountModel.singleAccount
    if (this.isActive) _.unset(resolve, 'lockReason')
    return resolve
  }

  applyPoint = (point = null) => {
    if (!point) return
    const resolve = {}
    _.each(this.pointFields(), field => {
      resolve[field] = point[field]
    })

    if (resolve.partnerOrganization) {
      if (!_.find(this.partners, m => m.id === resolve.partnerOrganization.id)) {
        resolve.partners = _.uniqBy(deepMerge.all([[], this.partners, [resolve.partnerOrganization]]), 'id')
      }
      resolve.partnerOrganization = resolve.partnerOrganization.id
    }
    if (resolve.macroRegion) {
      resolve.macroRegion = resolve.macroRegion.id
    }
    if (resolve.retail) {
      if (!_.find(this.retails, m => m.id === resolve.retail.id)) {
        resolve.retails = _.uniqBy(deepMerge.all([[], this.retails, [resolve.retail]]), 'id')
      }
      resolve.retail = resolve.retail.id
    }
    if (resolve.segment) {
      if (!_.find(this.segments, m => m.id === resolve.segment.id)) {
        resolve.segments = _.uniqBy(deepMerge.all([[], this.segments, [resolve.segment]]), 'id')
      }
      resolve.segment = resolve.segment.id
      resolve.isRequiredSegment = true
    } else {
      resolve.isRequiredSegment = false
    }
    if (resolve.partnerBank) {
      if (!_.find(this.banks, m => m.id === resolve.partnerBank.id)) {
        resolve.banks = _.uniqBy(deepMerge.all([[], this.banks, [resolve.partnerBank]]), 'id')
      }
      resolve.partnerBank = resolve.partnerBank.id
    }
    if (resolve.callCenter) {
      if (!_.find(this.callCenterList, m => m.id === resolve.callCenter.id)) {
        resolve.callCenterList = _.uniqBy(deepMerge.all([[], this.callCenterList, [resolve.callCenter]]), 'id')
      }
      resolve.callCenter = resolve.callCenter.id
    }
    if (resolve.callCenterAgent) {
      if (!_.find(this.callCenterAgentList, m => m.id === resolve.callCenterAgent.id)) {
        resolve.callCenterAgentList = _.uniqBy(deepMerge.all([[], this.callCenterAgentList, [resolve.callCenterAgent]]), 'id')
      }
      resolve.callCenterAgent = resolve.callCenterAgent.id
    }
    if (!_.isEmpty(resolve.services)) {
      resolve.services = _.map(resolve.services, s => ({ key: s.id, label: s.name }))
    }
    if (!_.isEmpty(resolve.managers)) {
      resolve.managersList = []
      _.each(resolve.managers, (manager, index) => {
        resolve.managersList.push([])
        resolve.managersList[index].push({
          id: manager.id,
          name: manager.name
        })
      })
    }

    this.applyData({ ...resolve, isShowForceValidate: true })
  }

  getPoint = async (id) => {
    if (this.id) return
    try {
      this.applyData({ isLoadingPoint: true })
      const point = await FactoryProvider.PointProvider.getPoint(id)
      this.applyPoint(point)

      this.BankAccountModel.applyParams({ partnerId: this.partnerOrganization, pointId: this.id })
    } catch (e) {
      notifyError('Ошибка получения информации о точке', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isLoadingPoint: false })
    }
  }

  applyDataForUser = () => {
    if (!this.id) return
    FactoryModel.UserModel.setField('dataFromPoint')({
      point: {
        id: this.id,
        name: this.name
      }
    })
  }

  save = async () => {
    if (!this.id) {
      await this.createPoint()
    } else await this.updatePoint()
    if (isSafe(this.segment)) {
      this.applyData({ isRequiredSegment: true })
    }
  }

  createPoint = async () => {
    if (!this.isNext) {
      this.applyData({ isShowForceValidate: true })
    } else {
      try {
        this.applyData({ isProcessSave: true })
        const id = await FactoryProvider.PointProvider.createPoint(this.point())
        this.applyData({ id })
        scrollToTop()
        push({ uri: '/points/[action]', href: `/points/${id}`, isShallow: true })
        notifySuccess('Точка успешно создана')
        this.BankAccountModel.applyParams({ partnerId: this.partnerOrganization, pointId: this.id })
      } catch (e) {
        notifyError('Ошибка создания точки', prepareErrorMessage(e))
        throw e
      } finally {
        this.applyData({ isProcessSave: false })
      }
    }
  }

  updatePoint = async () => {
    if (!this.isNext) {
      this.applyData({ isShowForceValidate: true })
    } else {
      try {
        this.applyData({ isProcessSave: true })
        await FactoryProvider.PointProvider.updatePoint(this.point())
        notifySuccess('Точка успешно сохранена')
        scrollToTop()
      } catch (e) {
        notifyError('Ошибка сохранения точки', prepareErrorMessage(e))
        throw e
      } finally {
        this.applyData({ isProcessSave: false })
      }
    }
  }

  getEditHistory = async (id, isForce = false) => {
    if (!_.isEmpty(this.historyEdit) && !isForce) return
    try {
      this.applyData({ isLoadingHistoryEdit: true })
      const historyEdit = await FactoryProvider.PointProvider.getEditHistory(id)
      this.applyData({ historyEdit })
    } catch (e) {
      notifyError('Ошибка получения истории изменений точки', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isLoadingHistoryEdit: false })
    }
  }

  getCodes = async id => {
    if (!_.isEmpty(this.codes)) return
    try {
      this.applyData({ isLoadingCodes: true })
      const codes = await FactoryProvider.PointProvider.getCodes(id)
      this.applyData({ codes })
    } catch (e) {
      notifyError('Ошибка получения кодов точки', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isLoadingCodes: false })
    }
  }

  setCode = action((peerID, name, value) => {
    const codes = this.codes.map(code => {
      if (code.peerID !== peerID) return code
      const isExistCode = _.findIndex(code.values, v => v.name === name) !== -1
      const values = isExistCode ? code.values : [...code.values, {
        name,
        value
      }]
      return {
        ...code,
        values: values.map(v => (v.name === name ? { ...v, value } : v))
      }
    })
    this.applyData({ codes })
  })

  removeCode = action(index => {
    this.codes.splice(index, 1)
  })

  addCode = action(() => {
    const bank = _.find(FactoryModel.BankModel.banks, b => b.id === this.bankCode)
    if (!bank) return

    this.codes.unshift({ peerID: bank.id,
      values: _.map(bank.templates, template => ({
        name: _.get(template, 'name', ''),
        value: template.value || template.defaultValue || ''
      })) })
    this.applyData({ bankCode: null })
  })

  updateCodes = async () => {
    if (!this.isValidCodes) {
      this.applyData({ isShowForceValidateCodes: true })
      return
    }
    try {
      this.applyData({ isSavingCodes: true })
      this.applyData({
        codes: this.codes.map(code => ({
          ...code,
          values: code.values.map(v => ({
            name: v.name,
            value: v.value.trim()
          }))
        }))
      })
      await FactoryProvider.PointProvider.updateCodes(this.id, this.codes, this.comment)
      notifySuccess('Коды точки успешно обновлены')
      this.getEditHistory(this.id, true)
    } catch (e) {
      notifyError('Ошибка обновления кодов точки', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isSavingCodes: false })
    }
  }

  addACGroup = action(() => {
    const acGroup = _.find(FactoryModel.AgentCommissionGroupListModel.groups, (acg) => acg.id === this.newACGroupID)
    const startsOn = new Date()
    const pointACGroup = { agentCommissionGroup: acGroup, startsOn: moment(startsOn - (startsOn.getTimezoneOffset() * 60000)).startOf('month') }
    this.applyData({ agentCommissionGroups: this.agentCommissionGroups.concat(pointACGroup), newACGroupID: null })
  })

  removeACGroup = action((index) => {
    const agentCommissionGroups = this.agentCommissionGroups.filter((v, i) => i !== index)
    this.applyData({ agentCommissionGroups })
  })

  setACGroupStartsOn = action((index, startsOn) => {
    this.agentCommissionGroups[index].startsOn = startsOn ? moment(startsOn - (startsOn.getTimezoneOffset() * 60000)).startOf('month') : startsOn
  })

  acGroupStartsOnError = (index) => {
    if (validRequired(this.agentCommissionGroups[index]?.startsOn)) return validRequired(this.agentCommissionGroups[index]?.startsOn)
    if (this.agentCommissionGroups.find((group, i) => (
      group.agentCommissionGroup.id === this.agentCommissionGroups[index].agentCommissionGroup.id
      && moment(group.startsOn)?.format(DATE_FORMAT) === moment(this.agentCommissionGroups[index]?.startsOn)?.format(DATE_FORMAT)
      && index !== i
    ))) {
      return 'Совпадает период'
    }
    return null
  }

  @computed get isValidACGroups() {
    if (_.isEmpty(this.agentCommissionGroups)) return true
    const invalidGroups = _.filter(this.agentCommissionGroups, (acg, index) => !_.isNull(this.acGroupStartsOnError(index)))
    return _.isEmpty(invalidGroups)
  }

  updateACGroups = async () => {
    if (!this.isValidACGroups) {
      this.applyData({ isShowForceValidateACGroups: true })
      return
    }
    try {
      this.applyData({ isSavingACGroups: true })
      await FactoryProvider.PointProvider.updateACGroups(this.id, this.agentCommissionGroups)
      notifySuccess('Группы агентского вознаграждения точки успешно обновлены')
    } catch (e) {
      notifyError('Ошибка обновления групп агентского вознаграждения точки', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isSavingACGroups: false })
    }
  }

  lockPoint = async () => {
    try {
      this.applyData({ isProcessLock: true })
      await FactoryProvider.PointProvider.lockPoint(this.id, this.isActive, this.isActive ? this.lockReason : '')
      notifySuccess(`Точка успешно ${this.isActive ? 'заблокирована' : 'разблокирована'}`)
      this.applyData({
        isActive: !this.isActive,
        lockReason: !this.isActive ? '' : this.lockReason
      })
    } catch (e) {
      notifyError(`Ошибка ${this.isActive ? 'блокировки' : 'разблокировки'} точки`, prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isProcessLock: false })
    }
  }

  getPointSettings = async (id) => {
    try {
      this.applyData({ isLoadingSettings: true })
      const settings = await FactoryProvider.PointProvider.getPointSettings(id)
      this.applyData({ settings })
    } catch (e) {
      notifyError('Ошибка получения настроек точки', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isLoadingSettings: false })
    }
  }
  setSetting = action((index, field) => (v) => {
    this.settings[index][field] = v
  })

  updatePointSettings = async () => {
    try {
      this.applyData({ isProcessSaveSettings: true })
      await FactoryProvider.PointProvider.updatePointSettings({ id: this.id, settings: this.settings })
      notifySuccess('Настройки точки успешно обновлены')
      scrollToTop()
    } catch (e) {
      notifyError('Ошибка обновления настроек точки', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isProcessSaveSettings: false })
    }
  }

  getPointAccreditation = async (id) => {
    try {
      this.applyData({ isLoadingAccreditation: true })
      const accreditation = await FactoryProvider.PointProvider.getPointAccreditation(id)
      this.applyData({ accreditation })
    } catch (e) {
      notifyError('Ошибка получения аккредитации точки', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isLoadingAccreditation: false })
    }
  }

  accreditPoint = async (bank_id) => {
    try {
      this.applyData({ isProcessSaveAccreditation: true })
      const accreditation = await FactoryProvider.PointProvider.accreditPoint({ id: this.id, bank_id })
      this.applyData({ accreditation })
      notifySuccess('Точка отправлена на аккредитацию')
      scrollToTop()
    } catch (e) {
      notifyError('Ошибка отправления точки на аккредитацию', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isProcessSaveAccreditation: false })
    }
  }

  updateCallCenterInfo = async () => {
    try {
      this.applyData({ isSavingCallCenterInfo: true })
      console.log(1)
      console.log(this.id)
      console.log(this.callCenterInfo)
      await FactoryProvider.PointProvider.updateCallCenterInfo(this.id, this.callCenterInfo)
      notifySuccess('Информация для КЦ успешно обновлена')
    } catch (e) {
      notifyError('Ошибка обновления информации для КЦ', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isSavingCallCenterInfo: false })
    }
  }
}

export { PointModel }
