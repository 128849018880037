import _ from 'lodash'

export const profileMap = ({ profile }) => {
  let lastName = ''
  let firstName = ''
  let patronymic = ''
  if (profile?.last_name && profile.first_name) {
    lastName = profile?.last_name ?? ''
    firstName = profile?.first_name ?? ''
    patronymic = profile?.middle_name ?? ''
  }

  const fullName = profile?.fullname ?? ''

  return {
    id: profile?._id ?? profile?.id ?? '',
    fullName,
    lastName,
    firstName,
    patronymic,
    mobilePhone: profile?.mobile_phone ? `${profile?.mobile_phone}`.substr(1) : '',
    email: _.get(profile, 'email', ''),

    role: _.get(profile, 'role', ''),
    isConfirmEmail: _.get(profile, 'email_confirmed', false),
    isConfirmMobilePhone: _.get(profile, 'mobile_phone_confirmed', false),

    referUrl: _.get(profile, 'refer_url', ''),
    canGenerateReferCode: _.get(profile, 'can_generate_refer_code', false),
    showManagerMotivation: _.get(profile, 'show_manager_motivation', false),
    iconColor: _.get(profile, 'icon_color', false),
    motivationUrl: _.get(profile, 'motivation_url', false)
  }
}

export const profileMapApi = ({ profile }) => {
  const resolve = {
    email: profile?.email
  }

  if (profile.mobilePhone) resolve.mobile_phone = `7${profile?.mobilePhone}`

  resolve.last_name = profile?.lastName
  resolve.first_name = profile?.firstName

  if (profile?.patronymic) resolve.middle_name = profile?.patronymic

  resolve.fullname = `${profile?.lastName} ${profile?.firstName}`
  if (profile?.patronymic) resolve.fullname += ` ${profile?.patronymic}`

  return resolve
}
