import _ from 'lodash'
import { moment, DATE_FORMATS, DATE_FORMAT } from 'uikit'
import { notNull } from './null'
import { addressMap, addressMapApi } from './address'
import { phoneSimpleMapApi, phoneSimpleMap } from './phone'
import { bundleMap } from './poscans'

const shortPartnerMap = ({ partner }) => ({
  id: partner.id ?? partner._id,
  name: _.get(partner, 'name', ''),
  inn: _.get(partner, 'inn', ''),
  kpp: _.get(partner, 'kpp', ''),
  isActive: partner?.enabled ?? partner?.active ?? true,
  dateTurnOff: partner.deactivate_date ? moment(partner.deactivate_date, DATE_FORMATS).toDate() : null,
  isApplicationUploaded: _.get(partner, 'application_uploaded', false),
  isProcessLock: false,
  lockReason: _.get(partner, 'lock_reason', '')
})

const bankAccountMap = ({ account }) => ({
  id: account?.id ?? account?._id,
  bik: account?.bik ?? '',
  rs: account?.rs ?? '',
  createdAt: account?.created_at ? moment(account?.created_at, DATE_FORMATS).toDate() : null,
  isActive: _.get(account, 'active', false),
  isProcessRemove: false,
  isProcessLock: false,
  isProcessSave: false,
  isChange: false,
  isProcessSaveSinglePoint: false,
  isAdded: false,
  points: _.map(account?.point_assignments, point => ({
    id: point?.id ?? point?._id ?? point?.point_id,
    createdAt: point?.created_at ? moment(point?.created_at, DATE_FORMATS).toDate() : null,
    isDisable: true,
    isSelect: true
  }))
})

const cleanBankAccountMap = account => ({
  id: account?.id,
  bik: account?.bik,
  rs: account?.rs,
  createdAt: account?.createdAt,
  isActive: account?.isActive,
  isProcessRemove: false,
  isProcessLock: false,
  isProcessSave: false,
  isChange: false,
  isProcessSaveSinglePoint: false,
  isAdded: false,
  points: account?.points
})

const partnerMap = ({ partner, isShort = true }) => {
  let resolve = {
    id: partner.id ?? partner._id,
    name: _.get(partner, 'name', '')
  }

  if (!isShort) {
    resolve = {
      ...resolve,
      inn: partner?.inn ?? partner?.tin ?? '',
      isActive: partner?.enabled ?? partner?.active ?? true,
      kpp: _.get(partner, 'kpp', ''),
      bankMixID: _.get(partner, 'bank_mix_id', null),
      dateTurnOff: partner.deactivate_date ? moment(partner.deactivate_date, DATE_FORMATS).toDate() : null,
      isApplicationUploaded: _.get(partner, 'application_uploaded', false),
      vatType: _.get(partner, 'vat_type', 'with_vat'),
      address: partner.address ? addressMap({ address: partner.address }) : null,
      urAddress: partner.ur_address ? addressMap({ address: partner.ur_address }) : null,
      contractNo: _.get(partner, 'contract_no', ''),
      contractDate: partner.contract_date ? moment(partner.contract_date, DATE_FORMATS).toDate() : null,
      channel: partner.channel ? partner.channel?.id : null,
      mainPartner: notNull(_.get(partner, 'main_partner'), mainPartner => ({
        id: mainPartner._id ?? mainPartner.id,
        name: _.get(mainPartner, 'name', '')
      })),
      contacts: _.map(_.get(partner, 'contacts', []), contact => ({
        email: contact.email,
        fullName: contact.fullname,
        position: contact.position,
        phone: phoneSimpleMap({ phone: contact.phone })
      })),
      bankAccounts: _.map(_.get(partner, 'bank_accounts', []), account => bankAccountMap({ account }))
    }
  }
  return resolve
}

const bankAccountMapApi = ({ account }) => ({
  bik: account.bik,
  rs: account.rs
})

const partnerMapApi = ({ partner }) => {
  const resolve = {
    name: partner.name,
    tin: partner.inn,
    kpp: partner.kpp,
    vat_type: partner.vatType,
    address: addressMapApi({ address: partner.address }),
    ur_address: addressMapApi({ address: partner.urAddress }),
    contract_no: partner.contractNo,
    contract_date: partner.contractDate ? moment(partner.contractDate, DATE_FORMATS).format(DATE_FORMAT) : null,
    main_partner_id: partner.mainPartner?.id ?? partner.mainPartner,
    channel_id: partner.channel,
    contacts: !_.isEmpty(partner.contacts) ? _.map(partner.contacts, contact => ({
      email: contact.email,
      fullname: contact.fullName,
      position: contact.position,
      phone: phoneSimpleMapApi({ phone: contact.phone })
    })) : null,
    bank_accounts: _.map(partner.bankAccounts, account => bankAccountMapApi({ account }))
  }

  return resolve
}

const partnerSettingsMap = (params) => (
  {
    settings: _.map(params?.settings, bankSetting => ({
      bank: {
        id: _.get(bankSetting, 'bank._id', ''),
        name: _.get(bankSetting, 'bank.name', '')
      },
      isDirectFinancing: _.get(bankSetting, 'direct_financing', false),
      isGoodsCostIncludeServices: _.get(bankSetting, 'goods_cost_include_services', false),
      isHireDiscount: _.get(bankSetting, 'hire_discount', false),
      discountMax: _.get(bankSetting, 'discount_max', 0),
      smsProductCode: _.get(bankSetting, 'sms_product_code', ''),
      pointsWithDistinctSettings: _.get(bankSetting, 'points_with_distinct_settings', []),
      isDiscountByTermEnabled: _.get(bankSetting, 'discount_by_term_enabled', false),
      discountByTerm: _.map(bankSetting.discount_by_term, discount => ({
        id: _.get(discount, '_id', ''),
        term: _.get(discount, 'term', ''),
        discount: _.get(discount, 'discount', '')
      }))
    })),
    estoreContacts: _.get(params, 'estore_contacts', ''),
    logoUrl: _.get(params, 'logo_url', ''),
    showServicesInSpecification: _.get(params, 'show_services_in_specification', false),
    allowSendWithoutConfirmationCode: _.get(params, 'allow_send_without_confirmation_code', false),
    allowSendWithoutPdAgreement: _.get(params, 'allow_send_without_pd_agreement', false),
    allowPassportRecognition: _.get(params, 'allow_passport_recognition', false),
    printCreditPaymentsMemo: _.get(params, 'print_credit_payments_memo', false),
    clientVerification: _.get(params, 'client_verification', false),
    lockReason: _.get(params, 'lock_reason', ''),
    automaticFinancing: _.get(params, 'automatic_financing', '')
  }
)

const partnerSettingsMapApi = (params) => {
  const resolve = {
    settings: _.map(params.settings, bankSetting => ({
      bank_id: _.get(bankSetting, 'bank.id', false),
      direct_financing: _.get(bankSetting, 'isDirectFinancing', false),
      goods_cost_include_services: _.get(bankSetting, 'isGoodsCostIncludeServices', false),
      hire_discount: _.get(bankSetting, 'isHireDiscount', false),
      discount_max: _.get(bankSetting, 'discountMax', 0),
      sms_product_code: _.get(bankSetting, 'smsProductCode', ''),
      discount_by_term_enabled: _.get(bankSetting, 'isDiscountByTermEnabled', false),
      discount_by_term: _.map(bankSetting.discountByTerm, discountByTerm => ({
        term: _.get(discountByTerm, 'term', 0),
        discount: _.get(discountByTerm, 'discount', 0)
      }))
    })),
    logo_url: params.logoUrl || null,
    estore_contacts: params.estoreContacts || null,

    show_services_in_specification: params.showServicesInSpecification,
    allow_send_without_confirmation_code: params.allowSendWithoutConfirmationCode,
    allow_send_without_pd_agreement: params.allowSendWithoutPdAgreement,
    allow_passport_recognition: params.allowPassportRecognition,
    print_credit_payments_memo: params.printCreditPaymentsMemo,
    client_verification: params.clientVerification,
    automatic_financing: params.automaticFinancing
  }

  if (params.lockReason !== '') resolve.lock_reason = params.lockReason
  return resolve
}

const partnerDocumentsDataMap = ({ documentsData }) => ({
  id: _.get(documentsData, 'id', ''),
  createdAt: documentsData.created_at ? moment(documentsData.created_at, DATE_FORMATS).toDate() : null,
  dateTurnOff: documentsData.deactivate_date ? moment(documentsData.deactivate_date, DATE_FORMATS).toDate() : null,
  isAutoDeactivationDisabled: _.get(documentsData, 'auto_deactivation_disabled', false),
  canUpdateDeactivation: _.get(documentsData, 'can_update_deactivation', false),
  bundles: _.map(documentsData.documents, bundle => bundleMap({ bundle })),
  poscansPackageID: _.get(documentsData, 'documents_package_id', '')
})

const partnerDocumentsDataMapApi = ({ documentsData }) => ({
  deactivate_date: documentsData.dateTurnOff,
  auto_deactivation_disabled: documentsData.isAutoDeactivationDisabled
})

export {
  shortPartnerMap,
  partnerMap, partnerMapApi,
  bankAccountMap, bankAccountMapApi, cleanBankAccountMap,
  partnerSettingsMap, partnerSettingsMapApi,
  partnerDocumentsDataMap, partnerDocumentsDataMapApi
}
